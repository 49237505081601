@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  overflow-x: hidden;
}

.active {
  color: #8e8e8e;
}

iframe {
  width: 100% !important;
  height: 45rem;
}

/* Position and sizing of burger button */
.bm-burger-button {
  display: block;
  position: absolute;
  width: 1.7rem;
  height: 1rem;
  left: 1.5rem;
  top: 45%;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #eeeeee;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  margin-top: 2rem;
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
.bm-menu-wrap {
  position: fixed;
  height: 100% !important;
  width: 100% !important;
}

/* General sidebar styles */
.bm-menu {
  background: #031c4e;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
  z-index: 40;
  height: 105vh !important;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #eeeeee;
  padding: 0.8em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
  z-index: 40;
}

/* LOGIN - CSS */

.section {
  width: 100%;
  position: relative;
  height: 100vh;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
}

.section.active {
  background: #1e1e1e;
}

.section .container {
  position: relative;
  width: 75%;
  height: 80%;
  background: #21366c;
  box-shadow: 0 1.5rem 5rem rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.section .container .user {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
}

.section .container .user .imgBx {
  position: relative;
  width: 50%;
  height: 100%;
  transition: 0.5;
  display: flex;
  align-items: center;
  justify-content: center;
}

.text-danger {
  color: red;
  font-size: 15px;
}

.section .container .user .imgBx img {
  width: 40rem;
}

.section .container .user .formBx {
  position: relative;
  width: 50%;
  height: 100%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4rem;
  transition: 0.5s;
}

.section .container .user .formBx h2 {
  font-size: 1.3rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.2rem;
  text-align: center;
  width: 100%;
  margin-bottom: 1rem;
  color: #555;
}

.section .container .user .formBx input {
  width: 100%;
  padding: 1rem;
  background: #f5f5f5;
  color: #333;
  border: none;
  outline: none;
  box-shadow: none;
  font-size: 1.1rem;
  margin: 0.8rem 0;
  letter-spacing: 0.1rem;
  font-weight: 300;
}

.section .container .user .formBx input[type="submit"] {
  max-width: 10rem;
  background: #bba388;
  color: #fff;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 0.1rem;
  transition: 0.5s;
}
.favorite-heart {
  color: red;
}

.unfavorite-heart {
  color: grey;
}

.section .container .user.signupBx .formBx input[type="submit"] {
  background: #111111;
}

.container:empty {
  display: none;
}

@media only screen and (min-width: 320px) and (max-width: 1024px) {
  .bm-burger-button {
    display: block;
  }
}
